
import React from "react";
import { Helmet } from "react-helmet";
import HeaderPage from "../components/header/landing/HeaderPage";
import FooterFive from "../components/footer/FooterFive";


const TermsOfUse = () => {
    return (
      <div className="main-page-wrapper font-gordita">
        <Helmet>
          <title>Shipify - Shipping Per Product</title>
        </Helmet>
        {/* End Page SEO Content */}
  
        <HeaderPage />
        {/* End HeaderLanding */}

        <div className="fancy-feature-ten">
            <div className="bg-wrapper pt-0 pb-0" style={{background: "white"}}>
                <div className="privacy-policy block-style-sixteen md-mb-90">
                    <div className="row policy-row m-auto">
                    <h4 className="title text-center">Terms of Use</h4>
                    <p className="policy-text text-center">Last Updated: 01/01/2024</p>

                    <h4 className="subtitle">1. Acceptance of Terms</h4>
                    <p className="policy-text">
                    By accessing or using Shipify's website at <a href="https://shipifyapp.io/" target="_blank" rel="noopener noreferrer">https://shipifyapp.io/</a> and our services, you agree to comply with and be bound by these Terms of Use.
                    </p>

                    <h4 className="subtitle">2. Description of Services</h4>
                    <p className="policy-text">
                    Shipify provides shipping management services. Your use of these services is subject to your acceptance and compliance with these Terms of Use and our Privacy Policy.
                    </p>

                    <h4 className="subtitle">3. User Accounts</h4>
                    <p className="policy-text">
                    You may need to create an account to use certain Shipify services. You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities that occur under your account.
                    </p>

                    <h4 className="subtitle">4. Prohibited Activities</h4>
                    <p className="policy-text">
                    You agree not to engage in any prohibited activities, including but not limited to unauthorized access, data scraping, or any action that disrupts the proper functioning of Shipify services.
                    </p>

                    <h4 className="subtitle">5. Intellectual Property</h4>
                    <p className="policy-text">
                    Shipify retains ownership of all intellectual property rights related to the services. You may not use, reproduce, or distribute any content from Shipify without explicit permission.
                    </p>

                    <h4 className="subtitle">6. Termination</h4>
                    <p className="policy-text">
                    Shipify reserves the right to terminate or suspend your account and access to services at its discretion, without prior notice, for any reason, including a breach of these Terms of Use.
                    </p>

                    <h4 className="subtitle">7. Disclaimer of Warranties</h4>
                    <p className="policy-text">
                    Shipify provides services on an "as is" basis, without any warranties. We make no guarantees regarding the accuracy, reliability, or availability of our services.
                    </p>

                    <h4 className="subtitle">8. Limitation of Liability</h4>
                    <p className="policy-text">
                    Shipify and its affiliates will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of, or inability to use, the services.
                    </p>

                    <h4 className="subtitle">9. Changes to Terms</h4>
                    <p className="policy-text">
                    Shipify may update these Terms of Use. Continued use of our services after changes constitutes acceptance. Users will be notified of significant changes.
                    </p>

                    <h4 className="subtitle">10. Governing Law</h4>
                    <p className="policy-text">
                    These Terms of Use are governed by and construed in accordance with the laws of Israel. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in Israel.
                    </p>

                    <h4 className="subtitle">Contact Us</h4>
                    <p className="policy-text">
                    If you have questions or concerns about our Terms of Use,<br></br>contact us at <a href="mailto:contact@shipifyapp.io">contact@shipifyapp.io</a>.
                    </p>
                    
                    </div>
                </div>       
            </div>
        </div>
      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://shipifyapp.io/"
            target="_blank"
            rel="noreferrer"
          >
            Shipify App
          </a>{" "}
          inc.
        </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default TermsOfUse;