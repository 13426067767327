import React from "react";
import { Link } from "react-router-dom";


const featureList = [
  {
    icon: "149",
    title: "Editable Text",
    description: ` Take full control of the text you want to present to your
    customers, no matter which language you use, we support them all.`,
  },
  {
    icon: "163",
    title: "Custom Colors & Settings",
    description: ` Keep your brand front & center with your customized color, styles and settings that
    suits you.`,
  },
  {
    icon: "159",
    title: "Fully Responsive",
    description: ` It doesn't matter if your customer is on a computer screen, tablet
    or mobile, our solution covers them all.`,
  },
];


const FeaturesBlock = () => {
  return (
    <>
    
      <div className="block-style-sixteen md-mb-90" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/icons8-web-80.png" alt="icon" width="28" height="28" />
              </div>
              <h3 className="title">Display Methods Everywhere</h3>
              <p>
                Seamlessly showcase your crafted shipping methods effortlessly on 
                product & cart pages, and during checkout.
              </p>
              {/* <Link to="/solution-management">
                <img src="images/icon/72.svg" alt="icon" />
              </Link> */}
            </div>
          </div>
        </div>
        <img
          src="images/media/display-app-block.png"
          alt=""
          className="shapes screen-one"
        />
      </div>
      {/* /.block-style-sixteen */}



      <div data-aos="fade-up" className="block-style-thirty block-style-sixteen pt-0 pb-0 md-pb-40 md-mb-90" >
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="text-block md-pb-60 md-ps-15">
              <div className="feature">FEATURES</div>
              <div className="title-style-nine mb-60 md-mb-40">
                <h2>Control App Block elements super easy</h2>
              </div>

              <ul className="feature-list-one">
                {featureList.map((list, i) => (
                  <li key={i} data-aos="fade-up" >
                    <img
                      src={`images/icon/${list.icon}.svg`}
                      alt="icon"
                      className="icon"
                      style={{borderRadius: 0}}
                    />
                    <strong>{list.title}</strong>
                    <span>{list.description}</span>
                  </li>
                ))}
              </ul>
              {/* End .feature-list-one */}

              {/* <a href="#" className="theme-btn-thirteen mt-20">
                Learn More
              </a> */}
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div data-aos="fade-left" className="col-xl-6 col-lg-6">
            <img src="images/media/fully-customized-banner.png" alt="screen" />
          </div>
        </div>
      </div>




      {/* <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/output-onlinegiftools.gif" alt="icon" width="28" height="28" />
              </div>
              <h3 className="title">Multi-Region Support</h3>
              <p>
                Go global with ease. Our app supports a multi-region selector
                for countries, and handles language and currency change, ensuring tailored 
                experience for every customer.
              </p>
            </div>
          </div>
        </div>
        <img
          src="images/media/country-support.png"
          alt="screen"
          className="shapes screen-two"
        />
      </div> */}
      
    </>
  );
};

export default FeaturesBlock;
