import React from "react";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";


const ContactModal = ({isOpen=false, toggleModal=()=>{}}) => {

    const closeModal = () => {
        toggleModal()
    }

    return <> 
        <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
        >
        <div className="box_inner ">
        <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={closeModal}>
            <img src="images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
            <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                    “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                </div>
                </div>
                <img
                src="https://www.shipifyapp.io/images/media/contact-us.png"
                alt=""
                className="illustration mt-auto"
                />
            </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
            <h2 className="form-title">Contact us</h2>
            <HeaderPopupForm closeModal={closeModal}/>
            </div>
            {/*  /.right-side */}
        </main>
        {/* /.main-body */}
        </div>
        </Modal>
        {/* End  Modal For Request a demo */}
    </>
}

export default ContactModal;