import React from "react";
// Route Specific
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES
import ProductLanding from "../views/all-home-pages/ProductLanding";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";







// Not Found Page
import NotFound from "../views/NotFound";



const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={< ProductLanding/>} />
        <Route exact path="/privacy-policy/" element={< PrivacyPolicy/>} />
        <Route path="/terms-of-use/" element={< TermsOfUse/>} />



        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
