import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
import FancyFeatureEleven from "../../components/features/FancyFeatureEleven";
import FooterFive from "../../components/footer/FooterFive";
import FeaturesBlock from "../../components/features/FeaturesBlock";
import PricingFive from "../../components/pricing/PricingFive";
import ContactModal from "../../components/modal/ContacModal";
import { FaRegCalendarCheck } from "react-icons/fa";
import Modal from "react-modal";



const ProductLanding = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [isBookCall, setIsBookCall ] =useState(false)
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const CALENDLY_MEETING_LINK = 'https://calendly.com/shipifyapp-sales/demo-with-shipify'

  useEffect(() => {
    // Check if 'contact' parameter with value 'open' exists in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const contactParam = urlParams.get('contact');
    if (contactParam === 'open') {
      setIsContactModalOpen(true); // Open contact modal if parameter exists and its value is 'open'
    }
  }, []);

  const toggleContactModal = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };

  const toggleBookCallModal = () => {
    setIsContactModalOpen(isContactModalOpen === true ?? false);
    setIsBookCall(!isBookCall)
  };

  const closeModal = () => {
    setIsIframeLoading(true)
    toggleBookCallModal()
  }


  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsIframeLoading(false);
    }, 1500);
  };


  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>Shipify - Shipping Per Product</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLanding />
      {/* End HeaderLanding */}

      <div id="home">
        <HeroBannerFive />
      </div>
      {/* End HeaderBannerFive */}

     
      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-150 md-pt-0">
      <div className="container">
            <div className="row pb-50 pt-25">
              <div className="col-xl-11 col-lg-10 col-md-9 m-auto">
                <div className="title-style-six text-center">
                  <h6>Our Product</h6>
                  <h2>
                 Break free from cart-wide shipping limitations, with custom methods <span>per product</span>
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
        
        <div id="product" className="inner-container">
          
          <FancyFeatureEleven />
        </div>
        <img src="images/shape/139.svg" alt="shape" className="shapes shape-one"></img>
      </div>
      {/* /.fancy-feature-eleven */}

      
      <div id="features" className="fancy-feature-ten pt-0 md-pt-70">
        <div>
          <div className="bg-wrapper pt-0 pb-0" style={{background: "white"}}>
          <FeaturesBlock />
          </div>
        </div>
      </div>




{/* 	=====================================================
				Pricing Section Five
			===================================================== */}
      <div className="pricing-section-four  pt-150 md-mt-150 sm-mt-80 md-pt-80">        
        <div data-aos="fade-up" className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Marketplace, small store or niche? We've got you <span>covered.</span>
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>


        <div data-aos="fade-up" className="fancy-feature-ten pt-150 md-pt-70" style={{background: ""}}>
      <div id="book-call">
        <div className="pt-100 pb-80 book-call-demo-block">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-last">
              <div className="title-style-six md-p0">
                <h3 style={{color: 'white', fontWeight: '500' }}>
                   Book a Zoom meeting with us
                </h3>
                <p className="text-xs" style={{color: 'white'}}>
                   We’ll be glad to show you a demo, understand your needs, and help you set up the app.
                </p>
              </div>
              <button className="mt-20 book-call-block-button" onClick={toggleBookCallModal}>
                <FaRegCalendarCheck/>
              <span>Book a call</span>
            </button>
            </div>
            <div data-aos="fade-left" className="book-image-wrapper">
                <img src="images/icon/book-call-banner.png" className="book-image"/>
              </div>
          </div>
        </div>
        </div>
        </div>


        <div id="pricing" className="fancy-feature-ten block-style-sixteen pt-0" style={{background: ""}}>
        <div className="pt-100 pb-80 pricing-features-block" style={{background: "#F9F9F9"}}>
          <div className="row align-items-center">
            <div data-aos="fade-left" className="col-lg-5 order-lg-last">
              <div className="title-style-six ps-5 md-p0">
                <h6 style={{ fontSize: "15px" }}>Our Pricing</h6>
                <h2>
                  Choose your <span>Plan</span>
                </h2>
                {/* <p className="text-xs">
                  Lorem ipsum dolor on adipisci elit sed do eiusmod liu.
                </p> */}
              </div>
              {/* /.title-style-six */}
            </div>
            {/* End .col */}

            <div data-aos="fade-up" className="col-lg-7">
              <div className="pricing-table-area-five md-mt-60">
                <img
                  src="images/icon/88.png"
                  alt="icon"
                  className="shapes shape-one"
                />
            
                <PricingFive />
              </div>
              {/* /.pricing-table-area-five */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
        </div>
      </div>
      {/* /.pricing-section-five */}



      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/135.svg"
          alt="shape"
          className="shapes shape-new"
        />
        {/* <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2>
                  love our Shipify app? <br />
                  <span>Save $20</span> by grab it today.
                </h2>
              </div>
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            Try it risk free — we don’t charge our first 15 customers
          </p>
          <a
            href="https://apps.shopify.com/shipify-shipping-per-product"
            className="theme-btn-seven d-inline-flex btn-padding-new"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
            target="_blank"
          >
            <img src="images/icon/shopify.svg" alt="brand" className="icon m-auto" width="32" height="32" />
            <div className="d-grid justify-items-start align-items-center">
              <span className="lh-base align-items-center button-span-new">FIND IT ON THE</span>
              <strong className="lh-sm font-w-500 btn-strong-new">Shopify App store</strong>
            </div>
          </a>
        </div> */}
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-six */}

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive toggleContactModal={toggleContactModal}/>
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://shipifyapp.io/"
            target="_blank"
            rel="noreferrer"
          >
            Shipify App
          </a>{" "}
          inc.
        </p>
      </footer>
      <ContactModal isOpen={isContactModalOpen} toggleModal={toggleContactModal}/>
      <Modal 
        isOpen={isBookCall} 
        toggleModal={toggleBookCallModal}
        onRequestClose={closeModal}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner book-call-modal">
          <main className="book-call-main">
              <button className="close" onClick={closeModal}>
              <img src="images/icon/close.svg" alt="close" />
              </button>
              {isIframeLoading && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
               )}
              <iframe 
                src={CALENDLY_MEETING_LINK} 
                style={{ width: '100%', border: 'none' }}
                title="Iframe Example"
                onLoad={handleIframeLoad}
              />
            </main>
        </div>
      </Modal>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default ProductLanding;
