import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";
Modal.setAppElement("#root");

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/profile.php?id=61564298190212",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/shipifyapp/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/shipify-shipping-per-product/",
  },
  // {
  //   icon: "fa-youtube",
  //   link: "https://www.youtube.com/",
  // },
];

const FooterFive = ({toggleContactModal}) => {

const [isOpen, setIsOpen] = useState(false);

function toggleContactModal() {
  setIsOpen(!isOpen);
}
  return (
    <>
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-3">
        <div className="logo">
          <a href="/">
            <img src="/images/logo/Shipify-Logo-Big-V1.3.png" width="175" alt="brand" />
          </a>
        </div>
        <a
            href="https://apps.shopify.com/shipify-shipping-per-product"
            className="theme-btn-seven d-inline-flex btn-padding-new"
            target="_blank"
            style={{alignItems: 'center', maxHeight: '45px', marginTop: '15px'}}
          >
            <img src="images/icon/shopify.svg" alt="brand" className="icon m-auto" width="32" height="32" />
            <div className="d-grid justify-items-start align-items-center">
              <span className="lh-base align-items-center button-span-new" style={{fontSize: '9px'}}>FIND IT ON THE</span>
              <strong className="lh-sm font-w-500 btn-strong-new" style={{fontSize: '15px'}}>Shopify App store</strong>
            </div>
          </a>
          
      </div>
      {/* End .col */}

      <div className="col-lg-3 h-100-px">
        <div className="title">Policies</div>
        <div className="text-center">
          <Link to="/privacy-policy" className="email">
            Privacy Policy
          </Link>
        </div>

        <div className="text-center">
          <Link to="/terms-of-use" className="email">
            Terms of use
          </Link>
        </div>
      </div>

      <div className="col-lg-3 h-100-px">
        <div className="title">Find us on Social Media</div>
        <ul className="d-flex justify-content-center social-icon">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* End .col */}

      <div className="col-lg-3 h-100-px">
        <div className="title">We’r always happy to help.</div>
        <div className="text-center">
        <button className="email m-auto" onClick={toggleContactModal}>
            contact@shipifyapp.io
          </button>
        </div>
      </div>
      {/* End .col */}
    </div>


    <Modal
        isOpen={isOpen}
        onRequestClose={toggleContactModal}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleContactModal}>
              <img src="https://www.shipifyapp.io/images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                  </div>
                </div>
                <img
                  src="https://www.shipifyapp.io/images/media/contact-us.png"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              <h2 className="form-title">Contact us</h2>
              <HeaderPopupForm />
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
      {/* End  Modal For Request a demo */}
  </>
  );
};

export default FooterFive;
