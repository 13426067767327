import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { customerRegisterAPI } from "../../api/api";

const HeaderPopupForm = ({closeModal = () => {}}) => {
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(" Name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
    sendMessage: Yup.string().required("Please,leave us a message."),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const [disabledButton, setDisabledButton] = useState(false)

  function onSubmit(data, e) {
    setDisabledButton(true)
    customerRegisterAPI(data.name, data.email, data.sendMessage).then(res => {
      setDisabledButton(false)
      closeModal()
    }).catch(error => {
      setDisabledButton(false)
      closeModal()
    })
  }

  return (
    <>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="messages"></div>
        <div className="row controls">
          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>Name</label>
              <input
                type="text"
                placeholder="Your Name"
                name="name"
                {...register("name")}
                className={`${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>Email*</label>
              <input
                placeholder="Email Address"
                name="email"
                type="text"
                {...register("email")}
                className={` ${errors.email ? "is-invalid" : ""}`}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-30">
              <label>Message*</label>
              <textarea
                placeholder="Your message"
                name="sendMessage"
                type="text"
                {...register("sendMessage")}
                className={`${errors.sendMessage ? "is-invalid" : ""}`}
              ></textarea>
              {errors.sendMessage && (
                <div className="invalid-feedback">
                  {errors.sendMessage?.message}
                </div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button className="theme-btn-seven w-100" disabled={disabledButton}>{disabledButton ? "Sending" : "Send Message"}</button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default HeaderPopupForm;
