import React from "react";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import './PricingFive.css'

const PricingContent = [
  // {
  //   icon: "86",
  //   packName: "Early Birds",
  //   features: [
  //     "Custom Shipping Methods",
  //     "Display Everywhere",
  //     "Responsive App Block",
  //     "Multi-Region Support",
  //     "Custom Service"
  //   ],
  //   price: "FREE",
  //   trialText: "30-Day Free Trial ",
  //   activeClass: "active",
  // },
  {
    icon: "85",
    icon2: "159",
    packName: "PRO",
    features: [
      // "All Free Plan features",
      // "Priority Support",
      // "Advanced Customization",
      // "Exclusive Updates"
      // "Create Custom Shipping Methods Per Product",
      // "Choose Shipping Methods Already on Product Page",
      // "Control Your App Block Settings, Styling and Text",
      "Display & Select Rates on Product Page",
      "Import AliExpress Shipping Rates",
      "Chrome Extension for DSers App Users",
      "Create Custom Rates Per Product",
      "Control App Block Settings and Styling",
      "Multi Countries & Currencies Support",
      "Live Chat and Email Support Service",
    ],
    price: "$ 19.90",
    fee: "$ 0.25",
    trialText: "30-Day Free Trial ",
    activeClass: "most-popular",
  },
];

const PricingFive = () => {
  return (
    <div className="row align-items-center justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-md-7 pb-50" key={i}>
          <Tilt>
            <div className={`pr-table-wrapper ${val.activeClass}`}>
              <img
                src={`images/icon/${val.icon}.svg`}
                alt="icon"
                className="icon"
              />
              <img
                src={`images/shape/${val.icon2}.svg`}
                className="popular-badge"
                style={{display:"none"}}
                alt="shape"
              />
              <div className="pack-name">{val.packName}</div>
              <ul className="pr-feature">
                {val.features.map((list, i) => (
                  <li key={i}>{list}</li>
                ))}
              </ul>
              <div className="plan-prices">
                <div className="price"><b>{val.price}</b> / Month</div>
                <div className="fee">+{val.fee} per order</div>
              </div>
              <div className="trial-text" style={{color:"gray"}}>{val.trialText}</div>

              <Link
                to="/signup"
                className="trial-button hover-reverse-gr-bg-one gr-bg-one"
              >
                {" "}
                Sign up
              </Link>
            </div>
            {/* /.pr-table-wrapper */}
          </Tilt>
        </div>
      ))}
    </div>
  );
};

export default PricingFive;
