
import React from "react";
import { Helmet } from "react-helmet";
import HeaderPage from "../components/header/landing/HeaderPage";
import FooterFive from "../components/footer/FooterFive";


const PrivacyPolicy = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>Shipify - Shipping Per Product</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderPage />
      {/* End HeaderLanding */}

      <div className="fancy-feature-ten">
        <div className="bg-wrapper pt-0 pb-0" style={{ background: "white" }}>
          <div className="privacy-policy block-style-sixteen md-mb-90">
            <div className="row policy-row m-auto">
              <h4 className="title text-center">Shipify - Shipping Per Product Privacy Policy</h4>
              <h4 className="subtitle">Introduction</h4>
              <p className="policy-text">Shipify - Shipping Per Product ("the App") provides custom shipping rates tailored per product ("the Service") to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</p>
              <h4 className="subtitle">Personal Information the App Collects</h4>
              <p className="policy-text">When you install the App, we are automatically able to access certain types of information from your Shopify account, including:</p>
              <ul className="policy-text" style={{listStyle: 'circle', paddingLeft: '50px'}}>
                <li>Store collections API to create shipping groups & rates.</li>
                <li>Store markets API to select countries for shipping groups & rates.</li>
                <li>Carrier service API for display shipping rates at checkout.</li>
                <li>Shipping zones API to ensure carrier service installed on the store.</li>
                <li>Store products API to determine applicable shipping rates.</li>
                <li>Store orders API to giver user statistics and for app pricing.</li>
              </ul>
              <p className="policy-text">Additionally, we utilize the following webhooks to stay updated on relevant changes within your store:</p>
              <ul className="policy-text" style={{listStyle: 'circle', paddingLeft: '50px'}}>
                <li>New order created.</li>
                <li>Change in the store's markets.</li>
                <li>Change, add, delete, or edit a product.</li>
                <li>Change in the shipping zones of the store.</li>
                <li>Adding, deleting, changing, and editing collections.</li>
                <li>Theme changes to check if the app block and app embed are enabled.</li>
              </ul>
              <h4 className="subtitle">How Do We Use Your Personal Information?</h4>
              <p className="policy-text">The personal information we collect from you and your customers is essential for us to deliver and operate the Service effectively. Specifically, we use this information to:</p>
              <ul className="policy-text" style={{listStyle: 'circle', paddingLeft: '50px'}}>
                <li><strong>Custom Shipping Rates Per Product</strong>: Create and customize shipping rates for each product, ensuring precision and suitability for your store's unique needs.</li>
                <li><strong>Enhance Management Capabilities</strong>: Provide comprehensive control over the creation and management of shipping methods, allowing for detailed customization of names, pricing, applicable countries, and specific products or collections.</li>
                <li><strong>Optimize On-site Display</strong>: Utilize app blocks and app embeds created with Theme app extensions to seamlessly display shipping methods on product pages and the cart page, enhancing the user experience.</li>
                <li><strong>Streamline Checkout Integration</strong>: Implement a unique carrier service for your store, complete with custom details, to ensure accurate and efficient display of shipping methods at the checkout page.</li>
              </ul>
              <h4 className="subtitle">Cookies and Tracking Technologies</h4>
              <p className="policy-text">To optimize your experience with our App, we use cookies and similar tracking technologies to collect and store certain information. Cookies are data files placed on your device or computer that often include an anonymous unique identifier. We use cookies to:</p>
              <ul className="policy-text" style={{listStyle: 'circle', paddingLeft: '50px'}}>
                <li>Understand and save your preferences for future visits.</li>
                <li>Compile aggregate data about site traffic and site interactions to offer better site experiences and tools in the future.</li>
              </ul>
              <p className="policy-text">You can control your cookie preferences through your browser settings. For more information about cookies, and how to disable them, visit <a href="http://www.allaboutcookies.org" style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org</a>.</p>
              <h4 className="subtitle">Data Security</h4>
              <p className="policy-text">Shipify employs robust security measures to protect your information from unauthorized access, alteration, or disclosure. The security of your data is our priority.</p>
              <h4 className="subtitle">Sharing Your Personal Information</h4>
              <p className="policy-text">Shipify does not sell, trade, or rent your personal information to third parties. We do not share your information with anyone except as necessary to provide and improve our services and comply with legal obligations.</p>
              <h4 className="subtitle">Data Retention</h4>
              <p className="policy-text">We retain all relevant order information from your store for our records and analytics unless and until you ask us to delete this information. This retention helps us improve our services and provide accurate analytics to our users.</p>
              <h4 className="subtitle">Changes</h4>
              <p className="policy-text">We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. Significant changes will be communicated to you, and your continued use of the App after such changes will constitute acceptance of the updated policy.</p>
              <h4 className="subtitle">Contact Us</h4>
              <p className="policy-text">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:contact@shipifyapp.io" style={{textDecoration:'underline'}}>contact@shipifyapp.io</a><br/><br/> or by mail at:<br/>Yaldei Theran 10 st, Rishon Lezion, Israel</p>
            </div>
          </div>
        </div>
      </div>
      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://shipifyapp.io/"
            target="_blank"
            rel="noreferrer"
          >
            Shipify App
          </a>{" "}
          inc.
        </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default PrivacyPolicy;