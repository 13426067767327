import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";

const App = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const handleLoad = () => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Listen for the window load event
    window.addEventListener('load', handleLoad);

    // Clean up the event listener
    return () => window.removeEventListener('load', handleLoad);
  }, [hash]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Shipify - Shipping Per Product</title>
        <meta property="og:site_name" content="shipify" />
        <meta
          property="og:url"
          content="https://shipifyapp.io/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Shipify - Shipping Per Product"
        />
        <meta
          name="keywords"
          content="shipping,shopify app, shopify, dropshipping, web-app, business, clean, corporate, creative, modern, multipurpose, sass, software company"
        />
        <meta
          name="description"
          content="Shipify Revolutionize Your Shipping Strategy, let you craft, customize, and control Shipping Methods Like Never Before"
        />
        <meta name="description" content="Shopify Application" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
    </>
  );
};

export default App;
