import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { FaEnvelope } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa";
import ContactModal from "../../modal/ContacModal";


Modal.setAppElement("#root");

const logo = "/images/logo/Shipify-Logo-Big-V1.3.png";
const CALENDLY_MEETING_LINK = 'https://calendly.com/shipifyapp-sales/demo-with-shipify'

const HeaderLanding = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);
  const [isTalkToUs, setIsTalkToUs ] =useState(false)
  const [isBookCall, setIsBookCall ] =useState(false)
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };


  const toggleContactModal = () => {
    setIsIframeLoading(true)
    setIsTalkToUs(!isTalkToUs);
  };

  const toggleBookCallModal = () => {
    setIsTalkToUs(isTalkToUs === true ?? false);
    setIsBookCall(!isBookCall)
  };

  const closeModal = () => {
    setIsIframeLoading(true)
    toggleBookCallModal()
  }


  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsIframeLoading(false);
    }, 1500);
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img src={logo} width="150" alt="brand" />
            </Link>
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "product",
                      "features",
                      "pricing",
                      "feedback",
                    ]}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#home"
                        
                      >
                        Home
                      </a>
                      {/* <div className="dropdown-menu">
                        <MegaMenuLanding />
                      </div> */}
                    </li>

                    <li className="nav-item">
                      <a href="#product" className="nav-link">
                        Product
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#features" className="nav-link">
                      Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget">
            <button className="demo-button talk-to-us" onClick={toggleContactModal}>
              <FaEnvelope/>
              <span>Talk to us</span>
            </button>

            <button className="demo-button book-demo-button" onClick={toggleBookCallModal}>
              <FaRegCalendarCheck/>
              <span>Book a call</span>
            </button>
          </div>
          
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/">
            <img src="/images/logo/Shipify-Logo-Big-V1.3.png" width="150" alt="brand" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="/images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={[
            "home",
            "product",
            "features",
            "pricing",
          ]}
          currentClassName="active"
          offset={-200}
        >
          <li className="nav-item">
            <a href="#home" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#product" className="nav-link" onClick={handleClick}>
              Product
            </a>
          </li>
          <li className="nav-item">
            <a href="#features" className="nav-link" onClick={handleClick}>
              Features
            </a>
          </li>
          <li className="nav-item">
            <a href="#pricing" className="nav-link" onClick={handleClick}>
              Pricing
            </a>
          </li>
          
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
      <Modal 
        isOpen={isBookCall} 
        toggleModal={toggleBookCallModal}
        onRequestClose={closeModal}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner book-call-modal">
          <main className="book-call-main">
              <button className="close" onClick={closeModal}>
              <img src="images/icon/close.svg" alt="close" />
              </button>
              {isIframeLoading && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
               )}
              <iframe 
                src={CALENDLY_MEETING_LINK} 
                style={{ width: '100%', border: 'none' }}
                title="Iframe Example"
                onLoad={handleIframeLoad}
              />
            </main>
        </div>
      </Modal>
      <ContactModal isOpen={isTalkToUs} toggleModal={toggleContactModal}/>

    </>
  );
};

export default HeaderLanding;
