import React from "react";
import { Link } from "react-router-dom";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";


const FancyFeatureEleven = () => {
  return (
    <>
      <div data-aos="fade-up" className="block-style-thirty block-style-sixteen pt-0 pb-0" style={{ background: "" }}>
        <div className="row ps-25 pb-0 pt-50">
          <div className="col-xl-5 col-lg-6 order-lg-last">
            <div className="text-block md-pb-60 md-ps-15">
              {/* <div className="feature">PRODUCT</div> */}
              <div className="title-style-nine mb-20 md-mb-30">
                <h2>Create Custom Shipping Methods Per Product</h2>
              </div>
              <p className="pb-0">
              Tailor shipping methods for each product, ensuring a personalized approach that fits your unique strategy.
              </p>
              <ul className="feature-list-two">
                <li>
                  Break free from cart-wide shipping <span>limitations</span> with multiple custom shipping methods per product.
                </li>
                <li>
                  More options, more <span>choices.</span> providing a diverse array of shipping
                  selections in product page for a personalized shopping journey.
                </li>
                <li>Define flexible <span>rates</span> to meet with your personal shipping strategy.</li>
                {/* <li>
                  Holds and catches their <span>attention</span> lorem.
                </li> */}
              </ul>
              {/* <a href="#" className="theme-btn-thirteen mt-30">
                Learn More
              </a> */}
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6 order-lg-first">
            <img src="images/media/create-custom-banner.png" alt="screen" />
          </div>
        </div>
       </div>
       <div data-aos="fade-up" className="block-style-thirty block-style-sixteen pt-0 pb-50" style={{ background: "rgb(241 161 62 / 25%)" }}>
        <div className="row ps-25 pb-0 pt-50">
          <div data-aos="fade-left" className="col-xl-5 col-lg-6 order-lg-last">
            <div className="text-block md-pb-60 md-ps-15">
              {/* <div className="feature">PRODUCT</div> */}
              <div className="title-style-nine mb-20 md-mb-30">
              <div
                className="icon d-flex align-items-center justify-content-center mb-25"
                style={{ background: "rgb(0 0 0 / 10%)" }}
              >
                <img src="images/icon/AliExpress-logo.svg" alt="icon" width="28" height="28" />
              </div>
                <h2>Fully integrated with AliExpress</h2>
              </div>
              <p className="pb-0">
              Import real-time shipping methods for all products in your store with AliExpress easily
              </p>
              <ul className="feature-list-two pt-0">
                <li>
                  Easily import real-time shipping methods from <span className="ali-span">AliExpress</span> for all your online store products with just one click using our intuitive Chrome extension.
                </li>
                <li>
                  Protect your online store from <span className="ali-span">unexpected</span> shipping expenses and create a new revenue stream by setting custom price rules for your shipping methods.                  
                </li>
                <li>
                  Choose which couriers to display to your customers and customize their display names, giving you full <span className="ali-span">control</span> to ensure they align perfectly with your store strategy.                
                </li>
              </ul> 
              </div>
          </div>
          <div data-aos="fade-right" className="col-xl-7 col-lg-6 order-lg-first" style={{display: "flex", alignItems:"center"}}>
            <img src="images/media/aliexpress-banner.png" alt="screen" />
          </div>
        </div>


        <div className="row ps-25 pb-0">
          <div data-aos="fade-right" className="col-xl-5 col-lg-6 order-lg-first">
            <div className="text-block md-pb-60 md-ps-15 chrome-section">
              {/* <div className="feature">PRODUCT</div> */}
              <div className="title-style-nine mb-20 md-mb-30">
              <div
                className="icon d-flex align-items-center justify-content-center mb-25"
                style={{ background: "rgb(0 0 0 / 10%)" }}
              >
                <img src="images/icon/chrome.svg" alt="icon" width="28" height="28" />
              </div>
                <h2>Automated Chrome Extension</h2>
              </div>
              <p className="pb-0">
              Using DSers? Import AliExpress Shipping methods for all of your online-store products in one click
              </p>
              <ul className="feature-list-two pt-0">
                <li>
                 Instantly import AliExpress <span className="ali-span">mappings</span> for all products in your store and seamlessly associate customer-selected shipping methods with DSers orders—all with a single click.
                </li>
                <li>
                Our Chrome extension is fully optimized for the <span className="ali-span">DSers</span> app, enabling complete automation of importing shipping methods and associating them with orders for streamlined fulfillment.                  
                </li>
              </ul> 
              </div>
          </div>
          <div data-aos="fade-left" className="col-xl-7 col-lg-6 order-lg-last" style={{display: "flex", alignItems:"center"}}>
            <img src="images/media/chrome-extension-banner.png" alt="screen" />
          </div>
        </div>
       </div>
       
      {/* <div className="mt-0 pb-100 md-pb-80 bottom-border md-ps-15" style={{ background: "" }}>
          <FancyFeatureTewentyEight />
      </div> */}
      
      
      {/* /.block-style-sixteen */}

      {/* <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              <h3 className="title">Custome & Plugin Development.</h3>
              <p>
                Commonly used in the graphic, print & publishing industris for
                previewing visual layout and mockups.
              </p>
              <Link to="/solution-management">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
        <img
          src="images/assets/screen_04.png"
          alt="screen"
          className="shapes screen-three"
        />
      </div> */}
      {/*  /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
